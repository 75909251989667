import NavBar2 from "../components/NavBar2";
import BreadCrumbSection from "../components/BreadCrumbSection";
import React from "react";
import Footer from "../components/Footer";
import {Link as Link2} from "react-router-dom";
import {Anchor} from "antd";

let {Link} = Anchor;

const AcademicCouncil = () => {
    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Administration" subTitle="Academic Council" bdLink="Academic Council"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <div className="col-lg-6">
                            <h1 className="text-theme-colored2">
                                Academic Council
                            </h1>
                            <div className="double-line-bottom-theme-colored-2"></div>
                        </div>

                        <div className="col-lg-6 text-right">
                            <Link2 to="/academic-council" smooth className='btn theme-btn  myButton_A'>
                                <Anchor affix="false" targetOffset="55">
                                    <Link className="nav-link" href="#academicmeeting" title="Minutes of Meetings"/>
                                </Anchor>
                            </Link2>
                        </div>
                    </div>

                    <div className='table-responsive'>
                        <table className="table-hover table margin-25-V">
                            <thead className="table-head-red-V">
                            <tr>
                                <th className="table-title-V">Sr. No</th>
                                <th className="table-title-V">Name</th>
                                <th className="table-title-V">Designation</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="table-body-padd-V">1.</td>
                                <td className="table-body-padd-V">Vice- Chancellor</td>
                                <td className="table-body-padd-V">Chairman
                                </td>
                            </tr>

                            <tr>
                                <td className="table-body-padd-V">2.</td>
                                <td className="table-body-padd-V">Dean of Faculties</td>
                                <td className="table-body-padd-V">
                                    Member
                                </td>
                            </tr>

                            <tr>
                                <td className="table-body-padd-V">3.</td>
                                <td className="table-body-padd-V">
                                    All Head of the Departments
                                </td>
                                <td className="table-body-padd-V">
                                    Members
                                </td>
                            </tr>

                            <tr>
                                <td className="table-body-padd-V">4.</td>
                                <td className="table-body-padd-V">
                                    The Principals of three
                                    colleges situated in the State
                                    to be nominated by the Board
                                </td>
                                <td className="table-body-padd-V">
                                    Members
                                </td>
                            </tr>

                            <tr>
                                <td className="table-body-padd-V">5.</td>
                                <td className="table-body-padd-V">
                                    Director, Indian Institute
                                    of Technology, Ropar (Punjab)
                                </td>
                                <td className="table-body-padd-V">
                                    Member
                                </td>
                            </tr>

                            <tr>
                                <td className="table-body-padd-V">6.</td>
                                <td className="table-body-padd-V">
                                    Director, Department of
                                    Technical Education and
                                    Industrial Training, Punjab
                                </td>
                                <td className="table-body-padd-V">
                                    Member
                                </td>
                            </tr>

                            <tr>
                                <td className="table-body-padd-V">7.</td>
                                <td className="table-body-padd-V">
                                    Two eminent industrialists to be
                                    nominated by the Board
                                </td>
                                <td className="table-body-padd-V">
                                    Members
                                </td>
                            </tr>

                            <tr>
                                <td className="table-body-padd-V">8.</td>
                                <td className="table-body-padd-V">
                                    Two eminent educationists to be
                                    nominated by the Board
                                </td>
                                <td className="table-body-padd-V">
                                    Members
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div id='academicmeeting'>
                        <div className="row">
                            <h1 className="text-theme-colored2">
                                Minutes of Meeting
                            </h1>
                            <div className="double-line-bottom-theme-colored-2"></div>
                        </div>
                        <div className="table-responsive">
                            <table className="table-hover table margin-25-V">
                                <thead className="table-head-red-V">
                                <tr>
                                    <th className="table-title-V">Sr. No</th>
                                    <th className="table-title-V"> Academic Council Meeting</th>
                                    <th className="table-title-V text-center">Agenda</th>
                                    <th className="table-title-V text-center">Minutes of Meeting</th>
                                </tr>
                                </thead>

                                <tbody>
                                {/* 1st */}
                                <tr>
                                    <td className="table-body-padd-V">1.</td>
                                    <td className="table-body-padd-V">
                                        1st Academic Council Meeting
                                    </td>
                                    <td className="table-body-padd-V text-center">
                                        <a href="#" className='btn theme-btn  myButton_A'>
                                            <i className='fa fa-file-pdf-o'></i>
                                        </a>
                                    </td>
                                    <td className="table-body-padd-V text-center">
                                        <a href="assets/pdf/academic-council/academic-council1(minutes-of-meeting).pdf"
                                           target='_blank' className='btn theme-btn  myButton_A'>
                                            <i className='fa fa-file-pdf-o'></i>
                                        </a>
                                    </td>
                                </tr>

                                {/* 2nd */}
                                <tr>
                                    <td className="table-body-padd-V">2.</td>
                                    <td className="table-body-padd-V">
                                        2nd Academic Council Meeting
                                    </td>
                                    <td className="table-body-padd-V text-center">
                                        <a href="assets/pdf/academic-council/academic-council2(agenda).pdf"
                                           target='_blank' className='btn theme-btn  myButton_A'>
                                            <i className='fa fa-file-pdf-o'></i>
                                        </a>
                                    </td>
                                    <td className="table-body-padd-V text-center">
                                        <a href="assets/pdf/academic-council/academic-council2(minutes-of-meeting).pdf"
                                           target='_blank' className='btn theme-btn  myButton_A'>
                                        <i className='fa fa-file-pdf-o'></i>
                                        </a>
                                    </td>
                                </tr>

                                {/* 3rd */}
                                <tr>
                                    <td className="table-body-padd-V">3.</td>
                                    <td className="table-body-padd-V">
                                        3rd Academic Council Meeting
                                    </td>
                                    <td className="table-body-padd-V text-center">
                                        <a href="assets/pdf/academic-council/academic-council3(agenda).pdf"
                                           target='_blank' className='btn theme-btn  myButton_A'>
                                            <i className='fa fa-file-pdf-o'></i>
                                        </a>
                                    </td>
                                    <td className="table-body-padd-V text-center">
                                        <a href="assets/pdf/academic-council/academic-council3(minutes-of-meeting).pdf"
                                           target='_blank' className='btn theme-btn  myButton_A'>
                                            <i className='fa fa-file-pdf-o'></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default AcademicCouncil;