import NavBar2 from "../components/NavBar2";
import BreadCrumbSection from "../components/BreadCrumbSection";
import Footer from "../components/Footer";
import {useEffect} from "react";
import {Link} from "react-router-dom";

const Placements = () => {
    useEffect(() => {
        document.title = "Placements";
    });

    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Placements" subTitle="Training & Placements"
                               bdLink="Placements"/>

            <div className="section3">
                <div className="container pt-90">
                    <h3 className={"my-font-style my-bold-font text-theme-colored2"}>
                        TPO Message
                    </h3>
                    <div className="double-line-bottom-theme-colored-2"></div>


                    <div className="mb-35 text-center">
                        <img src="assets/images/faculty/researchDeanSnap.jpg" className="HOD-image-v2" alt=""/>
                        <br/>
                        <br/>
                        <span>
                            <strong className="my-sub-titles certificate">
                                TPO Cell Incharge : Dr. Sarabjeet Singh Sidhu
                            </strong>
                        </span>
                        <br/>
                        <span>Email : <a className="mail-to text-theme-colored2 text-justify"
                                         style={{fontWeight: "600", fontSize: "16px"}}
                                         href="mailto:tpobcetgurdaspur@gmail.com">
                            tpobcetgurdaspur@gmail.com
                        </a></span>
                        <br/>
                        <span>Email : <a className="mail-to text-theme-colored2 text-justify"
                                         style={{fontWeight: "600", fontSize: "16px"}}
                                         href="mailto:tpo@bcetgsp.ac.in">
                            tpo@sbssugsp.ac.in
                        </a></span>
                        <br/>
                        <span>Contact No. : 01874-221463</span>
                    </div>

                    <div className="mb-35">
                        <h4 className="my-font-style text-theme-colored2">Message</h4>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <p className="font-p-v m-7-v">I take this opportunity to introduce myself as Incharge
                            Placement cell of Sardar Beant Singh State University, Gurdaspur, Punjab. This
                            university is established by Government of Punjab in 2021. It has become
                            one of the most sought after destinations for the young students planning to shape their
                            future in the ever growing field of engineering and technology. This university is running
                            under graduate and post graduate courses in the field of engineering & technology, computer
                            applications, management and agriculture and
                            has a track record of outstanding performances of its pass-outs in different spheres of
                            technical and administrative fields. The students of this university are selected through a
                            rigorous process at entry level and are among the best of the technical talent available.
                            They are guided and trained through a constantly updated curriculum, by a highly qualified
                            and experienced faculty to meet the fast changing needs of the technology. Our laboratories
                            are well equipped with modern, sophisticated instruments and machinery. We groom our
                            students, not only in the field of discipline, but also broaden their mindsets and create
                            positive attitude. I must thank the companies that have instilled implicit faith in our
                            students during and beyond the recruitment process. I take this opportunity on behalf of our
                            institute to extend an invitation for participating in Campus Recruitment Drive/Semester
                            Internship and tap the nascent talent available to the new recruiters who are yet to catch a
                            glimpse of our students for upcoming placements. It will be our proud
                            privilege to extend all logistic support and facilities. </p>
                    </div>

                    <div className="mb-35">
                        <h4 className="my-font-style text-theme-colored2">Placement 2017</h4>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <hr className="my-hr"/>
                        <div className="text-center">
                            <b className="text-blue text-justify">ELITE ACHIEVER</b>
                        </div>
                        <hr className="my-hr"/>

                        <p className="text-red text-justify">Our Student, Sudhanshu of Electronics and Communication
                            Engineering has
                            qualified</p>

                        <ol>
                            <li className="text-red text-justify">
                                <Link className="text-blue placement-file-links"
                                      to="../assets/pdf/result.pdf"
                                      target="_blank">Engineering Services Examination,2017</Link> held by
                                the UNION PUBLIC SERVICE COMMISSION <span style={{color: "green"}}>with AIR(All India Rank) 02</span> and
                                have been recommended for appointment to various Services/Posts in the
                                Ministries/Departments
                                concerned.
                            </li>

                            <li className="text-red text-justify">
                                <Link className="text-blue placement-file-links"
                                      to="../assets/images/Gate.jpg"
                                      target="_blank">GATE 2017 </Link>
                                <span style={{color: "green"}}>with AIR 139 </span>
                                and is placed in&nbsp;
                                <a className="text-blue placement-file-links"
                                   href="../assets/pdf/offer_letter.pdf"
                                   target="_blank">ONGC (A central public enterprise under the Government of
                                    India)</a>
                                &nbsp;at a Package of 12 LPA
                            </li>
                        </ol>
                    </div>

                    <div className="mb-35">
                        <hr className="my-hr"/>
                        <div className="text-center">
                            <b className="text-blue text-justify">Message From Students</b>
                        </div>
                        <hr className="my-hr"/>

                        <table className="table table-borderless">
                            <tr>
                                <td className="text-center">
                                    <img src="assets/images/cse.jfif"
                                         style={{height: "100px", width: "100px", border: "3px solid #000"}}
                                         alt="TPO Cell"
                                         title="TPO Cell student"/>
                                    <strong className="font-size-13">MANIK (CSE)</strong>
                                </td>
                                <td style={{padding: "20px 0"}}>
                                    <strong className="font-size-13">WORKING AT BASWARE CORPORATION</strong>
                                    <p>I was somewhat shy during my high school, but college really helped me to come
                                        out of my shell. I joined the debate team in my freshman year and developed
                                        confidence in presenting my views. Since then the College faculty and resources
                                        have helped me a lot in shaping my career.</p>
                                </td>
                            </tr>

                            <tr>
                                <td className="text-center">
                                    <img src="assets/images/BT.jpg"
                                         style={{height: "100px", width: "100px", border: "3px solid #000"}}
                                         alt="TPO Cell"
                                         title="TPO Cell student"/>
                                    <strong className="font-size-13">MANIK SHARMA (BT)</strong>
                                </td>
                                <td style={{padding: "20px 0"}}>
                                    <strong className="font-size-13">WORKING AT AGILENT TECHNOLOGIES</strong>
                                    <p>Role of BCET is very significant in my life. Today I am eligible for job it is
                                        all because of the dedication of my teaching faculty and the platform provided
                                        by the college. I am very excited as to be an engineer was my dream. To get
                                        placed in some company is all about confidence, passion and the practical
                                        experience which BCET gave me since my first day in college. I am thankful for
                                        the college and the atmosphere of hostel which helped to inculcate
                                        confidence.</p>
                                </td>
                            </tr>

                            <tr>
                                <td className="text-center">
                                    <img src="assets/images/BT1.jpg"
                                         style={{height: "100px", width: "100px", border: "3px solid #000"}}
                                         alt="TPO Cell"
                                         title="TPO Cell student"/>
                                    <strong className="font-size-13">TEJBEER SINGH (BT)</strong>
                                </td>
                                <td style={{padding: "20px 0"}}>
                                    <strong className="font-size-13">WORKING AT PanEra</strong>
                                    <p>College is the last institution before you step into reality of life and BCET
                                        hold everything for overall development of personality and calibre of every
                                        student who is hard working and passionate about his/her career. </p>
                                </td>
                            </tr>

                            <tr>
                                <td className="text-center">
                                    <img src="assets/images/ME.jpg"
                                         style={{height: "100px", width: "100px", border: "3px solid #000"}}
                                         alt="TPO Cell"
                                         title="TPO Cell student"/>
                                    <strong className="font-size-13">RAHUL KUMAR (ME)</strong>
                                </td>
                                <td style={{padding: "20px 0"}}>
                                    <strong className="font-size-13">WORKING AT PHOENIX LAMP LIMITED</strong>
                                    <p>I wants to thanks entire bcet family, especially mechanical department where all
                                        faculty and staffs are very supportive and about training and placement
                                        department, they all provide me right direction at right time, and during my 6
                                        month industrial training they help me in all way.</p>
                                </td>
                            </tr>

                            <tr>
                                <td className="text-center">
                                    <img src="assets/images/ECE.jpg"
                                         style={{height: "100px", width: "100px", border: "3px solid #000"}}
                                         alt="TPO Cell"
                                         title="TPO Cell student"/>
                                    <strong className="font-size-13">SRISHTI (ECE)</strong>
                                </td>
                                <td style={{padding: "20px 0"}}>
                                    <strong className="font-size-13">WORKING AT ZENSAR TECHNOLOGIES</strong>
                                    <p>“You don’t have to be great to start, but you have to start to be great”, this
                                        quote really suits my position. It’s really an accomplishing experience to get
                                        placed at the first attempt and was like a dream come true. Mere words are less
                                        to show my gratitude to BCET and its entire faculty who helped me at different
                                        stages starting from the beginning to the end. I am grateful to have been taught
                                        by such supportive professors, who helped me to shape my career endeavours and
                                        also helped me whenever required.</p>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div>
                        <hr className="my-hr"/>
                        <div className="text-center">
                            <b className="text-blue text-justify">Placement 2017</b>
                        </div>
                        <hr className="my-hr"/>

                        <p>
                            <a className="download-links" href="../assets/pdf/data2017.pdf" target="_blank">
                                Click here for full details regarding Placement 2017
                            </a>
                        </p>
                    </div>

                    <div className="mb-35">
                        <hr className="my-hr"/>
                        <div className="text-center">
                            <b className="text-blue text-justify">Placement 2018</b>
                        </div>
                        <hr className="my-hr"/>

                        <p>
                            <a className="download-links" href="../assets/pdf/data2018.pdf" target="_blank">
                                Click here for full details regarding Placement 2018
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default Placements;