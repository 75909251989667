import Footer from "./components/Footer";
import React, {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";
import {Link as Link2} from "react-router-dom";
import {Anchor} from "antd";

let {Link} = Anchor;

const FinanceCommittee = (props) => {

    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Administration" subTitle="Finance Committee" bdLink="Finance Committee"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <div className="col-lg-6">
                            <h1 className="text-theme-colored2">
                                Finance Committees</h1>
                            <div className="double-line-bottom-theme-colored-2"></div>
                        </div>
                        <div className="col-lg-6 text-right">
                            <Link2 to="/financecomittee" smooth className='btn theme-btn  myButton_A'>
                                <Anchor affix="false" targetOffset="55">
                                    <Link className="nav-link" href="#financemeeting" title="Minutes of Meetings"/>
                                </Anchor>
                            </Link2>
                        </div>
                    </div>

                    <div className="">
                        <table className="table-hover table margin-25-V">
                            <thead className="table-head-red-V">
                            <tr>
                                <th className="table-title-V">Sr. No</th>
                                <th className="table-title-V">Name</th>
                                <th className="table-title-V">Designation</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="table-body-padd-V">1.</td>
                                <td className="table-body-padd-V">Vice- Chancellor</td>
                                <td className="table-body-padd-V">Chairman
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">2.</td>
                                <td className="table-body-padd-V">Administrative Secretary to Government of
                                    Punjab,Department of Finance
                                </td>
                                <td className="table-body-padd-V">Member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">3.</td>
                                <td className="table-body-padd-V">Administrative Secretary to Government of Punjab,
                                    Department of Technical Education and Industrial Training
                                </td>
                                <td className="table-body-padd-V">Member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">4.</td>
                                <td className="table-body-padd-V">Director, Department of Technical Education and
                                    Industrial Training,Punjab
                                </td>
                                <td className="table-body-padd-V">Member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">5.</td>
                                <td className="table-body-padd-V">Registrar</td>
                                <td className="table-body-padd-V">Member Secretary
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div id='financemeeting'>
                        <div className="row">
                            <h1 className="text-theme-colored2">
                                Minutes of Meeting
                            </h1>
                            <div className="double-line-bottom-theme-colored-2"></div>
                        </div>
                        <div className="table-responsive">
                            <table className="table-hover table margin-25-V">
                                <thead className="table-head-red-V">
                                <tr>
                                    <th className="table-title-V">Sr. No</th>
                                    <th className="table-title-V"> Finance Committee Meeting</th>
                                    <th className="table-title-V text-center">Agenda</th>
                                    <th className="table-title-V text-center">Minutes of Meeting</th>
                                </tr>
                                </thead>

                                <tbody>
                                {/* 1st */}
                                <tr>
                                    <td className="table-body-padd-V">1.</td>
                                    <td className="table-body-padd-V">
                                        1st Finance Meeting
                                    </td>
                                    <td className="table-body-padd-V text-center">
                                        <a href="assets/pdf/financeMeeting/Finance_Meeting-1(Agenda).pdf"
                                           target='_blank' className='btn theme-btn  myButton_A'>
                                            <i className='fa fa-file-pdf-o'></i>
                                        </a>
                                    </td>
                                    <td className="table-body-padd-V text-center">
                                        <a href="assets/pdf/financeMeeting/Finance_Meeting-1(Minutes of Meeting).pdf"
                                           target='_blank' className='btn theme-btn  myButton_A'>
                                            <i className='fa fa-file-pdf-o'></i>
                                        </a>
                                    </td>
                                </tr>

                                {/* 2nd */}
                                <tr>
                                    <td className="table-body-padd-V">2.</td>
                                    <td className="table-body-padd-V">
                                        2nd Finance Meeting
                                    </td>
                                    <td className="table-body-padd-V text-center">
                                        <a href="assets/pdf/financeMeeting/Finance_Meeting-2(Agenda).pdf"
                                           target='_blank' className='btn theme-btn  myButton_A'>
                                            <i className='fa fa-file-pdf-o'></i>
                                        </a>
                                    </td>
                                    <td className="table-body-padd-V text-center">
                                        <a href="assets/pdf/financeMeeting/Finance_Meeting-2(Minutes of Meeting).pdf"
                                           target='_blank' className='btn theme-btn  myButton_A'>
                                            <i className='fa fa-file-pdf-o'></i>
                                        </a>
                                    </td>
                                </tr>

                                {/* 3rd */}
                                <tr>
                                    <td className="table-body-padd-V">3.</td>
                                    <td className="table-body-padd-V">
                                        3rd Finance Meeting
                                    </td>
                                    <td className="table-body-padd-V text-center">
                                        <a href="assets/pdf/financeMeeting/Finance_Meeting-3(Agenda).pdf"
                                           target='_blank' className='btn theme-btn  myButton_A'>
                                            <i className='fa fa-file-pdf-o'></i>
                                        </a>
                                    </td>
                                    <td className="table-body-padd-V text-center">
                                        <a href="assets/pdf/financeMeeting/Finance_Meeting-3(Minutes of Meeting).pdf"
                                           target='_blank' className='btn theme-btn  myButton_A'>
                                            <i className='fa fa-file-pdf-o'></i>
                                        </a>
                                    </td>
                                </tr>

                                {/* 4th */}
                                <tr>
                                    <td className="table-body-padd-V">4.</td>
                                    <td className="table-body-padd-V">
                                        4th Finance Meeting
                                    </td>
                                    <td className="table-body-padd-V text-center">
                                        <a href="assets/pdf/financeMeeting/Finance_Meeting-4(Agenda).pdf"
                                           target='_blank' className='btn theme-btn  myButton_A'>
                                            <i className='fa fa-file-pdf-o'></i>
                                        </a>
                                    </td>
                                    <td className="table-body-padd-V text-center">
                                        <a href="assets/pdf/financeMeeting/Finance_Meeting-4(Minutes of Meeting).pdf"
                                           target='_blank' className='btn theme-btn  myButton_A'>
                                            <i className='fa fa-file-pdf-o'></i>
                                        </a>
                                    </td>
                                </tr>

                                {/* 5th */}
                                <tr>
                                    <td className="table-body-padd-V">5.</td>
                                    <td className="table-body-padd-V">
                                        5th Finance Meeting
                                    </td>
                                    <td className="table-body-padd-V text-center">
                                        <a href="assets/pdf/financeMeeting/Finance_Meeting-5(Agenda).pdf"
                                           target='_blank' className='btn theme-btn  myButton_A'>
                                            <i className='fa fa-file-pdf-o'></i>
                                        </a>
                                    </td>
                                    <td className="table-body-padd-V text-center">
                                        <a href="assets/pdf/financeMeeting/Finance_Meeting-5(Minutes of Meeting).pdf"
                                           target='_blank' className='btn theme-btn  myButton_A'>
                                            <i className='fa fa-file-pdf-o'></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/*<h3 className="text-theme-colored2 m-7-v">*/}
                    {/*    The agenda papers and proceedings of the second meeting </h3>*/}
                    {/*<div className="double-line-bottom-theme-colored-2"></div>*/}

                    {/*<div className={"row"} id={"myButtons"}>*/}

                    {/*    <div className="col-sm-4 col-sm-offset-1 col-xs-6 text-center">*/}
                    {/*        <a href="assets/Downloads/Agenda-2-meet.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Agenda of <br/>Meeting</a>*/}
                    {/*    </div>*/}


                    {/*    <div className="col-sm-4 col-sm-offset-2 col-xs-6 text-center">*/}
                    {/*        <a href="assets/Downloads/Proceeding-2-meet.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Proceedings of <br/> Meeting</a>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                </div>
            </div>

            <Footer/>
        </>
    );
}

export default FinanceCommittee;

